import { ThemeProvider } from "styled-components";
import React from "react";

const Theme = (props) => {

    const theme = {
        colors: {
            jadeBlue: "#185BE0",
            porcelain: "#F8F9FA",
            lemon: "#FFF200",
            white: "#FFFFFF",

            cararra: "#EBEBE3",

            gray: "#808080",
            grayAsparagus: "#4E5C46",
            persianBlue: "#2B14D4",

            emerald: "#41DD62",
            radicalRed: "#FF2F77",
            vermilion: "#FF4A00",

            // 2023
            victoria: "#494294",
            niagara: "#0AAD8B",
            silver: "#C6C6C6",

            dropShadow: "rgba(24, 91, 224, 0.25)",
            insetLight: "rgba(248, 249, 250, 0.3)",

            // get text() {
            //     return this.grayAsparagus;
            // },


            get text() {
                return this.silver;
            },

            get background() {
                return this.victoria;
            },

            // get accent() {
            //     return this.radicalRed;
            // },


            get accent() {
                return this.niagara;
            },

            // accent: "#185BE0",
            // accentLight: "rgba(24,91,224,0.1)",
            // background: "#F8F9FA",
            // background: "#CCCCCC",

            shade: "rgba(24,91,224,0.5)",
            light: "#FFFFFF"
        },

        fonts: {
            executive: {
                family: "Executive",

                // weights
                medium: "500",
                bold: "600",

                // sizes
                standard: "1.125rem",
            },
            GtAmericaExpanded: {
                family: "GtAmericaExpanded",

                // weights
                medium: "500",
                bold: "600",

                // sizes
                standard: "1.125rem",
            },
            Outfit: {
                family: "Outfit",

                // weights
                medium: "500",
                bold: "600",

                // sizes
                standard: "1.125rem",
            },
            DiatypeRounded: {
                family: "ABC Diatype Rounded",

                // weights
                medium: "500",
                bold: "600",

                // sizes
                standard: "1.125rem",
            },
            DiatypeRoundedUltra: {
                family: "ABC Diatype Rounded Ultra",

                // weights
                medium: "500",
                bold: "600",

                // sizes
                standard: "1.125rem",
            },
        },

        text: {
            smallest: {
                family: "DiatypeRounded",
                // size: "1.25rem", // 20px
                size: {
                    regular: "0.625rem", // 10px
                    small: "0.625rem", // 10px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            smaller: {
                family: "DiatypeRounded",
                // size: "1.25rem", // 20px
                size: {
                    regular: "1.25rem", // 20px
                    small: "1rem", // 16px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            small: {
                family: "DiatypeRounded",
                // size: "1.25rem", // 20px
                size: {
                    regular: "1.5625rem", // 25px
                    small: "1.25rem", // 20px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            standard: {
                family: "DiatypeRounded",
                // size: "1.563rem", // 25px
                size: {
                    regular: "1.563rem", // 25px
                    small: "1.25rem", // 20px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "none",
            },
            headerTitle: {
                family: "DiatypeRounded",
                // size: "2.188rem", // 35px
                size: {
                    regular: "1.563rem", // 25px
                    small: "1.25rem", // 20px
                },
                weight: "800",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            medium: {
                family: "DiatypeRounded",
                // size: "2.188rem", // 35px
                size: {
                    regular: "2.1875rem", // 35px
                    small: "1.25rem", // 20px
                },
                weight: "800",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            large: {
                family: "DiatypeRounded",
                // size: "2.188rem", // 35px
                size: {
                    regular: "1.875rem", // 30px
                    small: "1.5rem", // 28px
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            larger: {
                family: "DiatypeRounded",
                // size: "2.5rem", // 40px
                size: {
                    regular: "3.125rem", // 50px
                    small: "1.25rem", // 20px
                },
                weight: "800",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            splashTag: {
                family: "DiatypeRounded",
                // size: "2.5rem", // 40px
                size: {
                    regular: "7rem",
                    small: "4rem",
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            cardsField: {
                family: "DiatypeRounded",
                // size: "1rem", // 16px
                size: {
                    regular: "1rem", // 16px
                    small: "1rem", // 16px
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            cardsName: {
                family: "DiatypeRounded",
                // size: "3.125rem", // 50px
                size: {
                    regular: "2.5rem", // 40px
                    small: "1.5rem", // 24px
                },
                weight: "400",
                lineHeight: "90%", // 45px
                color: "text",
                transform: "uppercase",
            },
            cardsTitle: {
                family: "DiatypeRounded",
                // size: "1.25rem", // 20px
                size: {
                    regular: "1.5rem", // 20px
                    small: "0.875rem", // 14px
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            dropZone: {
                family: "DiatypeRounded",
                // size: "1.875rem", // 30px
                size: {
                    regular: "1.875rem", // 30px
                    small: "1.5rem", // 24px
                },
                weight: "400",
                lineHeight: "auto",
                color: "text",
                transform: "uppercase",
            },
            buttonSmall: {
                family: "DiatypeRounded",
                // size: "1.25rem", // 20px
                size: {
                    regular: "1.25rem", // 20px
                    small: "1.25rem", // 20px
                },
                weight: "800",
                lineHeight: "1.25rem",
                color: "text",
                transform: "uppercase",
            },
            buttonStandard: {
                family: "DiatypeRounded",
                // size: "1.875rem", // 30px
                size: {
                    regular: "1.25rem", // 30px
                    small: "1.25rem", // 20px
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            bigTitle: {
                family: "DiatypeRounded",
                // size: "5rem", // 80px
                size: {
                    regular: "3.75rem", // 60px
                    small: "2rem", // 40px
                },
                weight: "800",
                lineHeight: "90%",
                color: "text",
                transform: "",
            },
            mediumTitle: {
                family: "DiatypeRoundedUltra",
                // size: "3.125rem", // 50px
                size: {
                    regular: "3.125rem", // 50px
                    small: "2.188rem", // 35px
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            projectName: {
                family: "DiatypeRounded",
                // size: "3.125rem", // 50px
                size: {
                    regular: "3.125rem", // 50px
                    small: "1.25rem", // 20px
                },
                weight: "400",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            catalogueTitle: {
                family: "DiatypeRounded",
                // size: "2.5rem", // 40px
                size: {
                    regular: "2rem", // 40px
                    small: "2rem", // 32px
                },
                weight: "800",
                lineHeight: "100%",
                color: "text",
                transform: "none",
            },
            catalogueDiploma: {
                family: "DiatypeRounded",
                // size: "0.875rem", // 14px
                size: {
                    regular: "1rem", // 16px
                    small: "1rem", // 16px
                },
                weight: "400",
                lineHeight: "100%",
                color: "text",
                transform: "uppercase",
            },
            catalogueName: {
                family: "DiatypeRounded",
                // size: "0.875rem", // 14px
                size: {
                    regular: "1.25rem", // 20px
                    small: "1.25rem", // 20px
                },
                weight: "bold",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            splashDiploma: {
                family: "DiatypeRounded",
                // size: "0.875rem", // 14px
                size: {
                    regular: "1.25rem", // 20px
                    small: "1.25rem", // 20px
                },
                weight: "700",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
            splashName: {
                family: "DiatypeRoundedUltra",
                // size: "0.875rem", // 14px
                size: {
                    regular: "2.1875rem", // 35px
                    small: "1.25rem", // 20px
                },
                weight: "700",
                lineHeight: "90%",
                color: "text",
                transform: "uppercase",
            },
            splashTitle: {
                family: "DiatypeRounded",
                // size: "0.875rem", // 14px
                size: {
                    regular: "1.25rem", // 20px
                    small: "1.25rem", // 20px
                },
                weight: "700",
                lineHeight: "90%",
                color: "text",
                transform: "none",
            },
        },

        sizes: {
            globalWidth: "73rem",
            globalMargins: "1rem",
            headerHeight: "3.5rem",
            headerHeightMobile: "28px"
        },

        breakPoints: {
            // s: "37.5rem", // 600px
            xs: "26.563rem", // 425px
            s: "48rem", // 768px
            ms: "53.125rem", // 850px
            m: "64rem", // 1024px
            l: "83.125rem" // 1330px
        },

        spacer: {
            smallest: ["0.25rem", "0.25rem"],
            smaller: ["0.5rem", "0.5rem"],
            small: ["1rem", "0.5rem"],
            medium: ["2rem", "1rem"],
            large: ["4rem", "2rem"],
            larger: ["8rem", "4rem"],
        },

        paddings: {
            buttonLink: {
                vertical: "0.75rem",
                horizontal: "1.25rem",
            },
        },

        radii: {
            standard: "1.25rem",
            buttonLink: "0.375rem",
            tile: "1rem",
        },

        filters: {
            cardDropShadow: "drop-shadow(-5px 3px 10px rgba(24, 91, 224, 0.25))",
            titleDropShadow: "drop-shadow(0px 0px 10px rgba(24, 91, 224, 0.25))"
        },

        cards: {
            standardSize: "432px",
            smallSize: "250px",
            smallerSize: "200px",
            smallestSize: "150px",
            padding: "20px"
        },

        time: {
            date: "DD.MM.YYYY",
            dateTime: "DD.MM.YYYY - HH:mm",
        }
    };

    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default Theme;
